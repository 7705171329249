<template>
  <div class="list-detail d-flex flex-column scroll-container">
    <v-container class="d-flex flex-column scroll-container" v-if="list">
      <h1 class="list-name">{{ list.name }}</h1>

      <div
        class="detail-table t-list-detail"
        v-if="list.wishlistItems.length + list.wishlistInactiveItems.length > 0"
      >
        <v-row
          no-gutters
          class="table-header"
          v-if="$vuetify.breakpoint.smAndUp"
        >
          <v-col cols="12" md="5">
            <strong>Prodotto</strong>
          </v-col>
          <v-col
            cols="4"
            md="2"
            class="table-column justify-left hidden-sm-and-down"
          >
            <strong>Prezzo unitario</strong>
          </v-col>
          <v-col
            cols="3"
            class="table-column justify-center hidden-sm-and-down"
          >
            <strong>Quantità</strong>
          </v-col>

          <v-col cols="1"></v-col>
        </v-row>

        <v-list class="t-detail-list" v-if="list">
          <ListItem
            v-for="item in list.wishlistItems"
            v-bind:key="item.itemId"
            :item="item"
            :isActive="true"
            @removeItem="removeFromList"
            @update="updateList"
          ></ListItem>
          <!-- :itemId="item.itemId" -->
          <!-- :product="item.product" -->
          <ListItem
            v-for="item in list.wishlistInactiveItems"
            v-bind:key="item.itemId"
            :item="item"
            @removeItem="removeFromList"
            :isActive="false"
          ></ListItem>
          <!-- :product="item.product"
            :itemId="item.itemId" -->
        </v-list>
      </div>
      <v-card
        light
        depresses
        v-if="
          list.wishlistItems.length + list.wishlistInactiveItems.length == 0
        "
        class="pa-2 text-center no-lists"
        style="width:100%;"
      >
        <v-card-title class="headline"
          >Nessun prodotto in questa lista</v-card-title
        >
      </v-card>

      <v-row no-gutters class="d-flex justify-space-between footer-btn">
        <v-col cols="12" md="8" class="d-flex flex-column flex-md-row">
          <v-btn
            outlined
            color="primary"
            x-large
            depressed
            to="/profile/lists"
            class="bg-white"
          >
            TORNA ALLE MIE LISTE
          </v-btn>
          <v-btn
            outlined
            color="primary"
            class="bg-white mt-2 mt-md-0 ml-md-2"
            x-large
            depressed
            :disabled="
              list.wishlistItems.length + list.wishlistInactiveItems.length == 0
            "
            @click="emptyList()"
          >
            SVUOTA LISTA
          </v-btn>
        </v-col>
        <v-col cols="12" md="4" class="d-flex justify-end">
          <v-btn
            color="primary"
            class="add-all-to-card"
            large
            depressed
            @click="addAllToCart"
            :disabled="
              list.wishlistItems.length + list.wishlistInactiveItems.length == 0
            "
          >
            AGGIUNGI TUTTO AL CARRELLO
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-spacer class="spacer-scroll"></v-spacer>
  </div>
</template>
<script>
import ListItem from "@/components/lists/ListItem";

import ListService from "~/service/listService";

import deliveryReactive from "~/mixins/deliveryReactive";
import login from "~/mixins/login";

import { mapActions } from "vuex";
var _this;

export default {
  name: "List",
  data() {
    return {
      list: null
    };
  },
  components: {
    ListItem
  },
  mixins: [deliveryReactive, login],
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    fetchList(listId) {
      ListService.getList(listId).then(list => {
        _this.list = list;
      });
    },
    async removeFromList({ itemId, product }) {
      let res = await _this.$dialog.confirm({
        text: global.vm.$t("message.removeItemFromList", {
          productName: product.name,
          productShortDescr: product.shortDescr
        })
      });
      if (res) {
        const newList = await ListService.removeItemFromList(
          _this.list,
          itemId,
          product.productId
        );
        _this.list = newList;
      }
    },
    async emptyList() {
      const textConfirm = `Sei sicuro di voler svuotare la lista ${_this.list.name}?`;

      let res = await _this.$dialog.confirm({
        text: textConfirm
      });
      if (res) {
        const res = await ListService.emptyList(this.list);

        _this.list = res;
      }
    },
    async addAllToCart() {
      if (await this.needLogin("addAllToCart")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            let res = await global.vm.$dialog.confirm({
              text: global.vm.$t("message.addAllToList")
            });
            if (res) {
              await _this.addProductsFromWishlist(_this.list.listId);
            }
          }
        }
      }
    },
    async updateList({ newQuantity, item }) {
      const res = await ListService.updateProductsInList(
        _this.list.listId,
        item,
        newQuantity
      );
      _this.list = res;
    },
    reload() {
      this.fetchList(this.$route.params.listId);
    }
  },
  created() {
    _this = this;
    this.reload();
  }
};
</script>
